import React from 'react';
import classnames from 'classnames';
import Props from './props';

const namespace = 'ui-homes-icon ui-homes-icon--chevron';
const ICON_ID = 'chevron_icon';

const IconChevron = ({ className = null }) => (
  <svg
    className={classnames(namespace, className)}
    viewBox="0 0 9 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconChevron.propTypes = Props.propTypes;

const Icon = React.memo(IconChevron);
Icon.ICON_ID = ICON_ID;

export default Icon;
