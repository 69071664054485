// Traemos nuestro builder porque Homes no tiene uno como vpp/search
// eslint-disable-next-line import/no-unresolved
import IconFactory from '@recommendations-fe/icons/factory-builder';

import {
  IconRecommendationsFull,
  IconRecommendationsMeliCoins,
  IconRecommendationsHeartEmpty,
  IconRecommendationsHeartFull,
  IconRecommendationsChevron,
} from '../icons/recommendations-fe/icon-generator';

const RecommendationsIconFactory = IconFactory([
  IconRecommendationsFull,
  IconRecommendationsMeliCoins,
  IconRecommendationsHeartEmpty,
  IconRecommendationsHeartFull,
  IconRecommendationsChevron,
]);

export default RecommendationsIconFactory;
