/* eslint-disable max-len */
import React from 'react';

import Props from './props';

const ICON_ID = 'hand_empty_icon';

const IconHeartEmpty = ({ className = null }) => (
  <svg className={className} viewBox="0 0 36 32">
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconHeartEmpty.propTypes = Props.propTypes;

const Icon = React.memo(IconHeartEmpty);
Icon.ICON_ID = ICON_ID;

export default Icon;
