import React from 'react';
import { Image } from 'nordic/image';

import Props from './props';

/* eslint-disable react/no-unknown-property */
/* eslint-disable max-len */
const IconMeliCoins = ({ className = null }) => (
  <div
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    fill="none"
    alt="Melicoin"
  >
    <Image src="icon-melicoin.svg" alt="" />
  </div>
);

IconMeliCoins.propTypes = Props.propTypes;

export default React.memo(IconMeliCoins);
