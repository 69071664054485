const itemMapper = (item) => {
  if (item.installments) {
    item.installments.freeInstallments = item.installments.free_installments;
    item.installments.free_installments = undefined;
  }

  return item;
};

export default itemMapper;
